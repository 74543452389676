<template>
  <div>
    <div class="carousel" v-if="images.length && !value">
      <div class="carousel-image">
        <img :src="images[carousel]" />
      </div>
      <div class="carousel-nav">
        <button @click="prevImage" class="carousel-prev" :disabled="carousel == 0">
          &larr;
        </button>
        <button @click="useCarouselImage">Choose</button>
        <button @click="nextImage" class="carousel-next" :disabled="carousel + 1 == images.length">
          &rarr;
        </button>
      </div>
    </div>

    <div class="non-carousel-image" v-if="value">
      <img :src="value" />
      <button @click="removeImage" class="remove-button">Remove</button>
    </div>

    <div class="modal-image-actions" v-if="!value">
      <input type="text" placeholder="Image URL" v-model="url" v-on:keyup.enter="urlChange" />
      <div class="actions-spacer">OR</div>
      <button @click="triggerUpload">Upload</button>
    </div>

    <input type="file" ref="fileInput" class="image-upload" @change="fileUpload" />
  </div>
</template>

<script>
import firebase from 'firebase';
import { validateImageUrl } from '@/utils';

export default {
  name: 'ImagePicker',
  props: {
    images: Array,
    value: String
  },
  data() {
    return {
      carousel: 0,
      url: null
    };
  },
  methods: {
    urlChange() {
      validateImageUrl(this.url).then(() => this.$emit('input', this.url));
    },
    removeImage() {
      this.$emit('input', null);
    },
    nextImage() {
      if (this.carousel + 1 < this.images.length) {
        this.carousel += 1;
      }
    },
    prevImage() {
      if (this.carousel > 0) {
        this.carousel -= 1;
      }
    },
    useCarouselImage() {
      this.$emit('input', this.images[this.carousel]);
    },
    triggerUpload() {
      this.$refs.fileInput.click();
    },
    fileUpload(event) {
      // eslint-disable-next-line prefer-destructuring
      const file = event.target.files[0];
      const storage = firebase.storage().ref(file.name);
      const upload = storage.put(file);

      upload.on(
        'state_changed',
        () => {},
        () => {},
        () => {
          upload.snapshot.ref.getDownloadURL().then(url => {
            this.$emit('input', url);
          });
        }
      );
    }
  }
};
</script>

<style scoped lang="scss">
.remove-button {
  display: block;
  margin: 0 auto;
}
.image-upload {
  display: none;
}
.modal-image-actions {
  display: flex;
  align-items: center;
  width: 70%;
  margin: 20px auto;
  justify-content: space-between;
}
.actions-spacer {
  width: 20%;
  text-align: center;
}
.carousel-nav {
  width: 50%;
  margin: 10px auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.carousel-image,
.non-carousel-image {
  max-width: 60%;
  margin: 0px 10px;
  text-align: center;
  margin: 0 auto;
}
.carousel-image img,
.non-carousel-image img {
  width: 100%;
  border: 1px solid #999;
}
</style>
