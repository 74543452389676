<template>
  <div class="login-overlay">
    <div class="login-content">
      <h2>The Door is Locked</h2>
      <p>Uh-oh! Looks like you haven't logged in yet and so you can't access the Buddy System.</p>
      <button class="login-button" v-on:click="login">Login with Google</button>
    </div>
  </div>
</template>

<style scoped lang="scss">
.login-overlay {
  background-color: rgba(22, 22, 22, 0.95);
  position: absolute;
  z-index: 999;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}
.login-content {
  padding: 10px;
  text-align: center;
}
.login-button {
  transition: 200ms all;
  padding: 8px 20px;
  background-color: #5a5;
  border: 1px solid #7b7;
  color: #fff;
  font-weight: bold;
  border-radius: 5px;
  cursor: pointer;
}
.login-button:hover {
  background-color: #494;
  border-radius: 10px;
}
</style>

<script>
import firebase from 'firebase';

export default {
  name: 'Login',
  data() {
    return {};
  },
  methods: {
    login() {
      const provider = new firebase.auth.GoogleAuthProvider();
      firebase
        .auth()
        .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
        .then(() => {
          firebase
            .auth()
            .signInWithPopup(provider)
            .then(result => {
              // This gives you a Google Access Token. You can use it to access the Google API.
              //   const token = result.credential.accessToken;
              // The signed-in user info.
              //   const user = result.user;
              console.log(result);
              this.$router.replace('/');
            })
            .catch(error => {
              console.log(error);
              // Handle Errors here.
              //   const errorCode = error.code;
              //   const errorMessage = error.message;
              //   // The email of the user's account used.
              //   var email = error.email;
              //   // The firebase.auth.AuthCredential type that was used.
              //   var credential = error.credential;
            });
        });
    }
  }
};
</script>
