export const validateUrl = str => {
  const pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i'
  ); // fragment locator
  return !!pattern.test(str);
};

export const validateImageUrl = url =>
  new Promise((resolve, reject) => {
    const timeout = 3000;
    let timedOut = false;
    let timer;
    const img = new Image();

    // Set callbacks
    img.onerror = () => {
      if (!timedOut) {
        clearTimeout(timer);
        reject(new Error('Invalid Image URL'));
      }
    };
    img.onabort = img.onerror;
    img.onload = () => {
      if (!timedOut) {
        clearTimeout(timer);
        resolve(url);
      }
    };

    // Change url
    img.src = url;

    // Set timeout
    timer = setTimeout(() => {
      timedOut = true;
      // reset .src to invalid URL so it stops previous
      // loading, but doesn't trigger new load
      img.src = '//!!!!/test.jpg';
      reject(new Error('Image URL timeout'));
    }, timeout);
  });

export const slugify = text => {
  let name = text.replace(/^\s+|\s+$/g, '');
  name = name.toLowerCase();
  name = name
    .replace(/[^a-z0-9 -]/g, '')
    .replace(/\s+/g, '-')
    .replace(/-+/g, '-');
  return name;
};
