import firebase from 'firebase/app';
import 'firebase/firestore';

firebase.initializeApp({
  apiKey: 'AIzaSyBXhE0b3UuUS2m9XVJcpXleQs2dySKTc2Q',
  authDomain: 'adamzara-f0d93.firebaseapp.com',
  databaseURL: 'https://adamzara-f0d93.firebaseio.com',
  projectId: 'adamzara-f0d93',
  storageBucket: 'adamzara-f0d93.appspot.com',
  messagingSenderId: '1017172623710'
});

export const db = firebase.firestore();
