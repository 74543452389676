<template>
  <div>
    <div class="breadcrumbs">
      <div class="crumb-title">Message Board</div>
    </div>
    <Messenger />
  </div>
</template>

<script>
import Messenger from '@/components/Messenger.vue';

export default {
  name: 'Messages',
  components: {
    Messenger
  }
};
</script>
