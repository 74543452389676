<template>
  <div class="container messenger">
    <div class="messages" ref="chatBox">
      <div class="message add-message">
        <div class="caption">Today</div>
        <textarea class="input-message" v-model="message"> </textarea>
        <button class="send-message" v-on:click="sendMessage">Post to a Buddy</button>
      </div>
      <Message :msg="msg" v-for="(msg, idx) in messages" :key="idx" />
    </div>
    <router-link to="/messages" tag="button" class="message-expand" v-if="limit">
      See All Messages
    </router-link>
  </div>
</template>

<script>
import Message from '@/components/Message.vue';
import firebase from 'firebase';
import { db } from '../db';

export default {
  name: 'Messenger',
  components: {
    Message
  },
  props: {
    limit: Number
  },
  data() {
    return {
      messages: [],
      message: ''
    };
  },
  firestore() {
    return {
      messages: this.$props.limit
        ? db
            .collection('messages')
            .orderBy('date', 'desc')
            .limit(this.$props.limit)
        : db.collection('messages').orderBy('date', 'desc')
    };
  },
  watch: {
    messages: function message() {
      setTimeout(() => {
        this.$refs.chatBox.scrollTop = this.$refs.chatBox.scrollHeight + 100;
      }, 100);
    }
  },
  methods: {
    sendMessage() {
      console.log(this.message, 'msg');
      if (this.message) {
        const user = firebase.auth().currentUser;
        db.collection('messages').add({
          message: this.message,
          date: new Date(),
          user: user.displayName,
          photo: user.photoURL
        });
        this.message = '';
      }
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.messenger {
  padding: 20px;
}

h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

.messages {
  font-size: 90%;
  color: #95deff;
  padding: 10px;
  text-align: center;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  display: flex;
}

.add-message {
  text-align: right;
}

.input-message {
  width: 100%;
  box-sizing: border-box;
  margin: 10px 0 5px 0;
  border: 1px solid #ddd;
  min-height: 150px;
  padding: 5px;
}
.send-message {
  background: #ff8499;
  color: #fff;
  border: 1px solid #ff8499;
  font-weight: bold;
  padding: 5px 20px;
  margin: 0;
  cursor: pointer;
}
.message-expand {
  width: 300px;
  margin: 10px auto;
  display: block;
  padding: 10px 0;
  border: 2px solid #ff8499;
}
</style>
