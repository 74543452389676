import Vue from 'vue';
import firebase from 'firebase';
import { firestorePlugin } from 'vuefire';
import App from './App.vue';
import router from './router';

import OutsideClick from './directives/OutsideClick';

Vue.config.productionTip = false;

Vue.use(firestorePlugin);

Vue.directive('outside-click', OutsideClick);

firebase.auth().onAuthStateChanged(() => {
  new Vue({
    router,
    render: h => h(App)
  }).$mount('#app');
});

window.firebase = firebase;
