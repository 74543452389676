<template>
  <div class="message">
    <div class="caption">
      {{ formatTime(msg.date.toDate()) }}
    </div>
    <div class="message-content">
      {{ msg.message }}
    </div>
    <div class="author">
      {{ msg.user }}
    </div>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  name: 'Message',
  props: {
    msg: Object
  },
  methods: {
    formatTime(date) {
      return moment(date).format('MMMM Do YYYY - h:mm a');
    }
  }
};
</script>

<style lang="scss">
.message::before {
  background-image: url('../assets/flower.svg');
  background-size: 40px 40px;
  content: '';
  display: inline-block;
  height: 40px;
  width: 40px;
  top: -25px;
  left: calc(50% - 20px);
  position: absolute;
}

.message {
  border: 10px solid #ffeaee;
  background: #fff;
  padding: 20px;
  width: 40%;
  margin: 10px;
  position: relative;
  text-align: left;
}

.message .caption {
  font-size: 90%;
  text-align: left;
  color: #777;
  border-bottom: 1px solid #777;
}

.message-content {
  min-height: 160px;
  padding: 10px 0;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  word-wrap: break-word;
  overflow: hidden;
  word-break: break-all;
  word-break: break-word;
}

.message .author {
  color: #777;
  text-align: right;
}

@media only screen and (max-device-width: 767px) {
  .message {
    width: 100%;
    margin: 10px 0;
  }
}
</style>
