<template>
  <div>
    <!-- <div class="video">
      <iframe width="560" height="315" src="https://www.youtube.com/embed/GHA4YSumbdU" frameborder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen></iframe>
    </div> -->

    <div class="home">
      <div class="hero">
        <h1>Welcome to the Buddy Site</h1>
        <div class="hero-content">
          <div class="hero-image">
            <img src="../assets/home.jpeg" />
            <div class="hero-overlay" v-on:click="navigate('lists')">
              <div class="hero-image-text">
                <h3>Shopping Lists</h3>
                <h5>Curated items specifically for Zara to go over</h5>
              </div>
            </div>
          </div>
          <div class="hero-image">
            <img src="../assets/presents.jpeg" />
            <div class="hero-overlay" v-on:click="navigate('lists/gifts')">
              <div class="hero-image-text">
                <h3>Gifts for Zara</h3>
                <h5>Cute things for the absolute cutest girl in the world</h5>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="center">
        <div class="section">
          <div class="section-bg">
            <h3>Upcoming Dates</h3>
          </div>
          <div class="section-content">
            <ul class="upcoming-dates">
              <li>
                <img src="../assets/blue-heart-icon.png" />
                <strong>May 23</strong> Mother's Day
              </li>
              <li>
                <img src="../assets/blue-heart-icon.png" />
                <strong>May 25</strong> 28 months since buddies met
              </li>
              <li>
                <img src="../assets/blue-heart-icon.png" />
                <strong>June 7</strong> 17 months of marriage
              </li>
            </ul>
          </div>
        </div>

        <div class="section section-reverse">
          <div class="section-bg">
            <h3>Recent Updates</h3>
          </div>
          <div class="section-content">
            <ul class="recent-updates">
              <li>
                <img src="../assets/heart-icon.png" />
                <strong>May 20</strong> Changed homepage gift navigation
              </li>
              <li>
                <img src="../assets/heart-icon.png" />
                <strong>February 24</strong> Created full message board page
              </li>
              <li>
                <img src="../assets/heart-icon.png" />
                <strong>February 9</strong> Recreated file structure
              </li>
              <li>
                <img src="../assets/heart-icon.png" />
                <strong>July 9</strong> Fixed bugs in list view, modal, and with the menu
              </li>
              <li>
                <img src="../assets/heart-icon.png" />
                <strong>July 2</strong> Added category system for the furniture and added cleaning
                list
              </li>
              <li>
                <img src="../assets/heart-icon.png" />
                <strong>July 1</strong> Updated dates and image for furniture
              </li>
              <li>
                <img src="../assets/heart-icon.png" />
                <strong>June 29</strong> Updated the buddy homepage
              </li>
              <li>
                <img src="../assets/heart-icon.png" />
                <strong>June 7</strong> Updated product ordering, added wedding video, and linked to
                skincare checker
              </li>
              <li>
                <img src="../assets/heart-icon.png" />
                <strong>June 2</strong> Worked on image scraper
              </li>
              <li>
                <img src="../assets/heart-icon.png" />
                <strong>May 28</strong> Login is working!
              </li>
              <li>
                <img src="../assets/heart-icon.png" />
                <strong>May 24</strong> Site moved to Google Cloud
              </li>
              <li>
                <img src="../assets/heart-icon.png" />
                <strong>May 20</strong> Gift site deployed
              </li>
              <li>
                <img src="../assets/heart-icon.png" />
                <strong>May 19</strong> Furniture site deployed
              </li>
            </ul>
          </div>
        </div>
      </div>
      <h2 class="message-board">Message Board</h2>
      <Messenger :limit="7" />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Messenger from '@/components/Messenger.vue';

export default {
  name: 'Home',
  components: {
    Messenger
  },
  methods: {
    navigate(path) {
      this.$router.push(path);
    }
  }
};
</script>

<style>
.hero {
  text-align: center;
  margin-bottom: 30px;
}

h1 {
  color: #fba7b7;
  text-shadow: 0 1px 1px #fff;
  display: inline-block;
  font-size: 300%;
  font-family: 'Dancing Script', sans-serif;
}

.hero-content {
  align-items: center;
  display: flex;
}

.hero-image {
  width: 50%;
  position: relative;
}

.hero-image-text {
  position: absolute;
  bottom: 20px;
  left: 30px;
  font-size: 120%;
  color: #fff;
  text-align: left;
  text-shadow: 0px 1px 1px #333;
}

.hero-image-text h3 {
  text-transform: uppercase;
  color: #fdd;
}

.hero-image-text h3,
.hero-image-text h5 {
  margin: 0;
}

.hero-image img {
  max-width: 100%;
}

.hero-overlay {
  background: rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  cursor: pointer;
  z-index: 9;
}

.hero-overlay:hover {
  background: rgba(0, 0, 0, 0.3);
}

.center {
  width: 100%;
  margin: 0 auto;
}

.section {
  display: flex;
  min-height: 400px;
  margin: 30px 0;
}

.section-reverse {
  flex-direction: row-reverse;
}

.section-reverse .section-bg {
  background-color: #95deff;
}

.section-reverse .section-bg h3 {
  color: #ffeaee;
  left: -340px;
  transform: rotate(90deg);
}

.section-bg {
  background: #ffeaee;
  background-image: url('../assets/swirl.png');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  text-align: center;
  width: 40%;
  position: relative;
}

.section-bg h3 {
  color: #95deff;
  transform: rotate(270deg);
  position: absolute;
  right: -60px;
  top: 40%;
  font-size: 230%;
  font-family: 'Dancing Script', sans-serif;
}

.section-content {
  padding: 20px;
  width: 60%;
  font-size: 120%;
  color: #777;
  display: flex;
  align-items: center;
}

.section-content ul li {
  padding: 5px 0;
}

.section-content ul li strong {
  padding-left: 6px;
  padding-right: 6px;
}

.upcoming-dates strong {
  color: #95deff;
}

.recent-updates strong {
  color: pink;
}

.upcoming-dates h3 {
  color: #df7971;
  text-transform: uppercase;
  font-size: 180%;
  margin-top: 0;
}

.video {
  margin: 0 auto;
  padding: 20px;
  text-align: center;
}

.container {
  padding: 20px;
  background: #fff;
}

.message-board {
  color: #95deff;
  text-shadow: 0 1px 1px #fff;
  font-size: 300%;
  font-family: 'Dancing Script', sans-serif;
  text-align: center;
}

@media only screen and (max-device-width: 768px) {
  .hero h1 {
    padding-bottom: 20px;
  }

  .hero-image {
    width: 100%;
  }

  .hero-content {
    flex-direction: column;
  }

  .section {
    flex-direction: column;
    min-height: auto;
    margin: 0 0 30px 0;
  }

  .section-bg {
    padding: 10px;
    width: 100%;
    box-sizing: border-box;
  }

  .section-bg h3,
  .section-reverse .section-bg h3 {
    transform: rotate(0deg);
    position: initial;
    margin: 0;
  }

  .section-content {
    width: 100%;
    box-sizing: border-box;
    padding-bottom: 0;
  }

  .messenger {
    padding-top: 0px !important;
  }

  .message-board {
    margin-top: 0;
  }
}
</style>
