<template>
  <div>
    <h1>Cleaning Routine</h1>
    <div class="center">
      <div class="section">
        <div class="section-bg">
          <h3>Daily Cleaning</h3>
        </div>
        <div class="section-content">
          <ul class="upcoming-dates">
            <li>Clean the kitchen</li>
            <li>Make the bed</li>
            <li>Load dishwasher every night</li>
            <li>Unload dishwasher every morning</li>
          </ul>
        </div>
      </div>

      <div class="section section-reverse">
        <div class="section-bg">
          <h3>Weekly Cleaning</h3>
        </div>
        <div class="section-content">
          <ul class="recent-updates">
            <li>Clean the bathroom counters</li>
            <li>Clean the toilets</li>
            <li>Scrub down the tub with baking soda</li>
            <li>Vacuum</li>
            <li>Disinfect floor</li>
            <li>Disinfect kitchen counter</li>
            <li>Laundry</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Cleaning'
};
</script>

<style scoped>
h1 {
  text-align: center;
  display: block;
}

.center {
  width: 100%;
  margin: 0 auto;
}

.section {
  display: flex;
  min-height: 400px;
  margin: 30px 0;
}

.section-reverse {
  flex-direction: row-reverse;
}

.section-reverse .section-bg {
  background-color: #95deff;
}

.section-reverse .section-bg h3 {
  color: #ffeaee;
  left: -420px;
  transform: rotate(90deg);
}

.section-bg {
  background: #ffeaee;
  background-image: url('../assets/swirl.png');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  text-align: center;
  width: 40%;
  position: relative;
}

.section-bg h3 {
  color: #95deff;
  transform: rotate(270deg);
  position: absolute;
  right: -60px;
  top: 40%;
  font-size: 230%;
  font-family: 'Dancing Script', sans-serif;
}

.section-content {
  padding: 20px;
  width: 60%;
  font-size: 120%;
  color: #777;
  display: flex;
  align-items: center;
}

.section-content ul li {
  padding: 5px 0 5px 25px;
  background: url('../assets/blue-heart-icon.png') no-repeat center left;
}

.section-reverse .section-content ul li {
  background-image: url('../assets/heart-icon.png');
}

.section-content ul li strong {
  padding-left: 6px;
  padding-right: 6px;
}

.upcoming-dates strong {
  color: #95deff;
}

.recent-updates strong {
  color: pink;
}

.upcoming-dates h3 {
  color: #df7971;
  text-transform: uppercase;
  font-size: 180%;
  margin-top: 0;
}

@media only screen and (max-device-width: 768px) {
  .section {
    flex-direction: column;
    min-height: auto;
    margin: 0 0 30px 0;
  }

  .section-bg {
    padding: 10px;
    width: 100%;
    box-sizing: border-box;
  }

  .section-bg h3,
  .section-reverse .section-bg h3 {
    transform: rotate(0deg);
    position: initial;
    margin: 0;
  }

  .section-content {
    width: 100%;
    box-sizing: border-box;
    padding-bottom: 0;
  }
}
</style>
