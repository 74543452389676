<template>
  <div v-if="list">
    <div class="breadcrumbs">
      <div class="crumb-side">
        <router-link to="/lists" v-if="list.id !== 'gifts'">
          &lt; Back<span class="list-text"> to Lists</span>
        </router-link>
      </div>
      <div class="crumb-title">{{ list.name }}</div>
      <div class="crumb-side"></div>
    </div>
    <div class="empty-list" v-if="!items.length">
      Loading...
    </div>
    <ul class="products lists">
      <li v-for="(product, idx) in items" :key="idx">
        <button class="list-edit" @click="openEditProduct(product)">Edit</button>
        <Heart
          class="product-favorite"
          @click.native="favorite(product)"
          v-bind:favorite="isFavorite(product)"
        />
        <a target="_blank" :href="product.url">
          <div class="image-container">
            <img :src="product.image" />
          </div>
          <div>{{ product.name }}</div>
        </a>
      </li>
    </ul>
    <div>
      <button class="add-product" @click="openAddProduct">+ Add</button>
    </div>

    <Modal v-if="editingProduct" @close="editingProduct = null">
      <div slot="header" class="edit-modal-header" v-if="editingProduct.id">
        <h3>Editing Item</h3>
        <button @click="deleteProduct(editingProduct)">Delete</button>
      </div>
      <h3 slot="header" v-if="!editingProduct.id">Add an Item</h3>
      <div slot="body" class="add-modal">
        <div class="modal-field">
          <label>URL</label>
          <input
            type="text"
            placeholder="URL"
            v-model="editingProduct.url"
            v-on:keypress.enter="lookupProductUrl"
          />
          <button @click="lookupProductUrl" class="load-button" :disabled="loadingScrape">
            <span v-if="!loadingScrape">Load</span>
            <span v-if="loadingScrape">Loading</span>
          </button>
        </div>
        <div>
          <div class="modal-field">
            <label>Name</label>
            <input type="text" placeholder="Name" v-model="editingProduct.name" />
          </div>
          <div class="modal-field">
            <label>Image</label>
            <ImagePicker
              class="image-picker"
              v-bind:images="editingProduct.images"
              v-model="editingProduct.image"
            />
          </div>
        </div>
        <div>
          <ul class="modal-rules">
            <li>All gifts should be thoughtful things that I think Zara would want</li>
            <li>
              Only buy jewelry from jewelry brands: Graff, Van Cleef, Cartier, and sometimes Chanel
            </li>
            <li>Don't be cheap on her birthday gift. One nice jewelry item each year</li>
            <li>
              Don't ever put expensive things that are worthless in the lists. Can have fun jewelry
              and things from other brands but don't put jewelry or gifts in the list that are a
              waste of money
            </li>
          </ul>
        </div>
        <div class="modal-actions">
          <button @click="updateProduct">
            <span v-if="editingProduct.id">Edit Item</span>
            <span v-if="!editingProduct.id">Add Item</span>
          </button>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import axios from 'axios';
import { db } from '@/db';
import Heart from '@/components/Heart.vue';
import Modal from '@/components/Modal.vue';
import ImagePicker from '@/components/ImagePicker.vue';
import firebase from 'firebase';

export default {
  name: 'List',
  props: {
    group: String
  },
  components: {
    Heart,
    Modal,
    ImagePicker
  },
  data() {
    return {
      showAdd: false,
      editingProduct: null,
      addUrl: '',
      loadingScrape: false,
      list: null,
      items: [],
      favorites: []
    };
  },
  methods: {
    favorite(product) {
      const favoriteList = this.favorites.ids;
      const favoriteIdx = favoriteList.indexOf(product.id);
      if (favoriteIdx > -1) {
        favoriteList.splice(favoriteIdx, 1);
      } else {
        favoriteList.push(product.id);
      }
      db.collection('favorites')
        .doc('XV5xVSbNri2NUveKW3cV')
        .set({ ids: favoriteList });
    },
    isFavorite(product) {
      if (this.favorites.ids) {
        return this.favorites.ids.includes(product.id);
      }
      return false;
    },
    openAddProduct() {
      this.editingProduct = {
        name: null,
        url: null,
        images: [],
        image: null
      };
    },
    openEditProduct(product) {
      this.editingProduct = { ...product, id: product.id, images: [] };
    },
    lookupProductUrl() {
      this.loadingScrape = true;
      axios
        .post('/scrape', { url: this.editingProduct.url, group: this.group })
        .then(resp => {
          this.loadingScrape = false;
          this.editingProduct = {
            ...this.editingProduct,
            name: resp.data.title,
            images: resp.data.images,
            image: null
          };
        })
        .catch(error => {
          console.log(error);
          this.loadingScrape = false;
        });
    },
    editProduct(product) {
      this.editingProduct = product;
    },
    deleteProduct(product) {
      // eslint-disable-next-line no-restricted-globals
      if (confirm('Are you sure you want to delete this item?')) {
        db.collection('lists')
          .doc(this.$route.params.id)
          .collection('items')
          .doc(product.id)
          .delete()
          .then(() => {
            this.editingProduct = null;
          });
      }
    },
    updateProduct() {
      // Save changes
      const data = {
        name: this.editingProduct.name,
        url: this.editingProduct.url,
        image: this.editingProduct.image,
        list: this.$route.params.id,
        group: this.$route.params.id === 'gifts' ? 'gifts' : 'furniture'
      };
      const itemCollection = db
        .collection('lists')
        .doc(this.$route.params.id)
        .collection('items');
      if (this.editingProduct.id) {
        itemCollection.doc(this.editingProduct.id).update(data);
      } else {
        data.date = firebase.firestore.Timestamp.fromDate(new Date());
        itemCollection.add(data);
      }
      this.editingProduct = null;
    }
  },
  firestore() {
    return {
      list: db.collection('lists').doc(this.$route.params.id),
      items: db
        .collection('lists')
        .doc(this.$route.params.id)
        .collection('items')
        .orderBy('date', 'desc'),
      favorites: db.collection('favorites').doc('XV5xVSbNri2NUveKW3cV')
    };
  }
};
</script>

<style scoped lang="scss">
.add-modal input {
  padding: 6px;
  width: 100%;
  box-sizing: border-box;
}
.add-modal img {
  max-width: 200px;
}
.add-modal ul {
  padding: 20px;
}
.add-modal ul li {
  list-style: disc;
  padding-left: 10px;
}
.product-favorite {
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 9;
}
.add-product {
  position: fixed;
  right: 20px;
  bottom: 20px;
  height: 80px;
  width: 80px;
  box-sizing: border-box;
  text-align: center;
  border-radius: 40px;
  background: #ea7890;
  border: 1px solid #fff;
  font-weight: bold;
  color: #fff;
  font-size: 18px;
  cursor: pointer;
  outline: 0;
  z-index: 10;
}
.add-product:hover {
  transform: rotate(10deg);
  transition: 200ms all;
  background: #c14f63;
}

.products li {
  padding: 30px;
}

.products li:hover .image-container {
  transform: scale(1.05);
  transition: 200ms all;
}

.products li .logo {
  position: absolute;
  top: 10px;
  left: 10px;
  height: 40px;
  padding: 8px;
  border-radius: 8px;
}
.load-button {
  margin-left: 10px;
}
.image-container {
  position: relative;
  margin-bottom: 30px;
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .products li {
    width: 50%;
  }

  .products li:nth-child(2n) {
    border-right-width: 0px;
  }
}

@media only screen and (max-device-width: 767px) {
  .list-text {
    display: none;
  }
}
</style>
