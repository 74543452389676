import Vue from 'vue';
import VueRouter from 'vue-router';
import firebase from 'firebase';
import Home from '../views/Home.vue';
import Login from '../views/Login.vue';
import List from '../views/List.vue';
import Lists from '../views/Lists.vue';
import Cleaning from '../views/Cleaning.vue';
import Messages from '../views/Messages.vue';
import Anniversary from '../views/Anniversary.vue';
import Anniversary2 from '../views/Anniversary2.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/lists',
    name: 'lists',
    component: Lists
  },
  {
    path: '/lists/:id',
    name: 'list',
    component: List
  },
  {
    path: '/messages',
    name: 'Messages',
    component: Messages
  },
  {
    path: '/cleaning',
    name: 'Cleaning',
    component: Cleaning
  },
  {
    path: '/anniversary',
    name: 'Anniversary',
    component: Anniversary
  },
  {
    path: '/anniversary2',
    name: 'Anniversary2',
    component: Anniversary2
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
];

const router = new VueRouter({
  routes
});

router.beforeEach((to, from, next) => {
  const user = firebase.auth().currentUser;
  console.log(user, 'user');
  if (to.name === 'Login' && user) {
    next({ name: 'Home' });
  } else if (to.name !== 'Login' && !user) {
    next({ name: 'Login' });
  } else {
    next();
  }
});

export default router;
