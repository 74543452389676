<template>
  <div class="heart" :class="{ favorite: favorite }">
    <i class="fa fa-heart"></i>
  </div>
</template>

<script>
export default {
  name: 'Heart',
  props: {
    favorite: Boolean
  }
};
</script>

<style scoped>
@keyframes heartbeat {
  0% {
    transform: scale(0.75);
  }
  20% {
    transform: scale(1);
  }
  40% {
    transform: scale(0.75);
  }
  60% {
    transform: scale(1);
  }
  80% {
    transform: scale(0.75);
  }
  100% {
    transform: scale(0.75);
  }
}
.heart {
  color: pink;
  font-size: 24px;
  background: #fff;
  height: 40px;
  width: 40px;
  line-height: 42px;
  text-align: center;
  border-radius: 40px;
  border: 2px solid #ccc;
  cursor: pointer;
  opacity: 0.8;
}
.heart.favorite {
  color: red;
  opacity: 1;
  border: 2px solid #999;
}
.heart:hover i {
  animation: heartbeat 1s infinite;
}
</style>
