<template>
  <div id="app">
    <div class="header" id="nav">
      <h2>
        <router-link to="/">Adam &amp; <span class="zara">Zara</span></router-link>
      </h2>
      <ul
        class="nav"
        :class="{ active: showMenu }"
        v-outside-click="{
          exclude: ['menu-icon', 'nav', 'fa'],
          handler: closeMenu
        }"
      >
        <li><router-link to="/lists">Shopping Lists</router-link></li>
        <li><router-link to="/lists/gifts">Gifts for Zara</router-link></li>
        <li><router-link to="/messages">Message Board</router-link></li>
        <li><router-link to="/anniversary">1 Year of commitment!</router-link></li>
        <li><router-link to="/anniversary2">18 Months of buddies!!</router-link></li>
        <li>
          <a
            href="https://docs.google.com/document/d/10-kEhStSaResMqCX9siuJanIl-Oj9Rbd7F6qdTdeUBM/edit"
            >Daily To-dos</a
          >
        </li>
        <li><a href="http://skincarechecker.com">Skincare</a></li>
        <li><a href="#" v-on:click="logout">Logout</a></li>
      </ul>
      <button class="logout" v-on:click="logout">Logout</button>
      <a href="#" class="menu-icon" v-on:click="menu">
        <i class="fa fa-bars"></i>
      </a>
    </div>
    <router-view :key="$route.fullPath" />

    <div class="footer">
      &copy; 2020-2021 Buddy System
    </div>
  </div>
</template>

<script>
import firebase from 'firebase';

export default {
  data() {
    return {
      showMenu: false
    };
  },
  methods: {
    menu() {
      this.showMenu = !this.showMenu;
    },
    closeMenu() {
      this.showMenu = false;
    },
    logout() {
      firebase
        .auth()
        .signOut()
        .then(() => {
          this.$router.replace('/login');
        });
    }
  },
  watch: {
    $route() {
      this.showMenu = false;
    }
  }
};
</script>

<style lang="scss">
html,
body,
ul,
li {
  padding: 0px;
  margin: 0;
  list-style: none;
  font-family: 'Lato', sans-serif;
}

body {
  padding-top: 68px;
  // background-color: #f0f0f0;
  // background-size: cover;
  // background-image: url('assets/cherry.jpg');
  // background-color: #861657;
  // background-image: linear-gradient(326deg, #861657 0%, #ffa69e 74%);
  background-color: #fff;
}

button {
  font-family: Lato, sans-serif;
  background: #ff8499;
  color: #fff;
  border: 1px solid #ff8499;
  font-weight: bold;
  padding: 5px 20px;
  margin: 0;
  outline: 0;
  cursor: pointer;
  transition: 200ms all;
}

button:hover {
  background: #fb9dac;
}

button[disabled] {
  background: #ccc;
  cursor: default;
  border: 1px solid #aaa;
}

.header {
  width: 100%;
  background: #fff;
  position: fixed;
  top: 0;
  text-align: center;
  box-sizing: border-box;
  z-index: 99;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logout {
  position: absolute;
  right: 20px;
  padding: 10px 30px;
  background: #fba7b7;
  border: 1px solid transparent;
  border-radius: 10px;
  color: #fff;
  font-weight: bold;
  cursor: pointer;
  display: none;
}

.logout:hover {
  border-bottom: 0;
  box-shadow: inset 0px 0px 0px 1px #fff;
  border: 1px solid #fba7b7;
  transition: 300ms all;
}

.header h2 a {
  text-decoration: none;
  color: #95deff;
}

.zara {
  color: #fba7b7;
}

.menu-icon {
  display: none;
}

.nav {
  display: flex;
  margin: 0 auto;
}

.nav li {
  margin: 0 15px;
}

.nav li a {
  text-decoration: none;
  font-weight: bold;
  color: #777;
  animation: all 200ms;
  padding-bottom: 3px;
  border-bottom: 3px solid transparent;
  transition: 200ms all;
}

.nav li a:hover {
  color: #444;
  border-bottom: 3px solid pink;
}

.menu-icon {
  display: block;
  color: pink;
  font-size: 160%;
  padding-left: 20px;
}

.nav {
  display: none;
  margin-left: 0;
}

.nav.active {
  display: flex;
  position: absolute;
  right: 0;
  top: 68px;
  flex-direction: column;
  background: #f9f9f9;
}

.nav li a {
  padding: 20px;
  display: block;
}

.footer {
  background: #95deff;
  color: #fff;
  font-weight: bold;
  padding: 20px;
  text-align: center;
  font-size: 90%;
}

@media only screen and (max-device-width: 768px) {
  .header {
    justify-content: space-between;
  }

  .nav li a {
    padding: 10px;
  }

  .nav.active {
    left: 0;
  }
}
</style>
