<template>
  <div class="anniversary">
    <h1>18 Months of Buddies Anniversary</h1>
    <div class="center">
      <div class="section">
        <ul class="upcoming-dates">
          <li>10:30am - Morning walk on the beach</li>
          <li>11am - Breakfast</li>
          <li>11:30am - Yoga</li>
          <li>11:45am - Go on a drive throughout Malibu hills</li>
          <li>12:45pm - Workout at home</li>
          <li>1pm - Lunch at home</li>
          <li>1:45pm-3:15pm - Spa session</li>
          <li>3:15-4:15pm - 2020 Goal Review</li>
          <li>4:30pm - Head to get dinner</li>
          <li>6:00 - Get home and get our anniversary dinner ready</li>
          <li>7:02pm - Many kisses and love!!!</li>
          <li>7:15pm - Card and love for my little one</li>
          <li>7:30pm - Watch videos I sent for our 12 month meeting anniversary</li>
          <li>8pm - Sunset beach walk / sitting on beach</li>
          <li>8:30pm - Picking out an anniversary purse together!</li>
          <li>9pm - Movie night!</li>
          <li>10:30pm - Cuddles and lots of loving</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Anniversary2'
};
</script>

<style scoped>
.anniversary {
  padding: 0px 20px;
}

h1 {
  text-align: center;
  display: block;
}

.center {
  width: 100%;
  margin: 0 auto;
}

.section {
  display: flex;
  min-height: 400px;
  align-items: center;
  justify-content: center;
  margin: 30px 0;
}

.section ul li {
  padding: 0px 0 20px 25px;
  line-height: 18px;
  font-size: 120%;
  background: url('../assets/heart-icon.png') no-repeat;
}

.section ul li strong {
  padding-left: 6px;
  padding-right: 6px;
}

.upcoming-dates strong {
  color: #95deff;
}

.recent-updates strong {
  color: pink;
}

.upcoming-dates h3 {
  color: #df7971;
  text-transform: uppercase;
  font-size: 180%;
  margin-top: 0;
}

@media only screen and (max-device-width: 768px) {
  .section {
    flex-direction: column;
    min-height: auto;
    margin: 0 0 30px 0;
  }

  .section-bg {
    padding: 10px;
    width: 100%;
    box-sizing: border-box;
  }

  .section-bg h3,
  .section-reverse .section-bg h3 {
    transform: rotate(0deg);
    position: initial;
    margin: 0;
  }

  .section-content {
    width: 100%;
    box-sizing: border-box;
    padding-bottom: 0;
  }
}
</style>
