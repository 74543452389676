<template>
  <div class="anniversary">
    <h1>1 Year Commitment Anniversary</h1>
    <div class="center">
      <div class="section">
        <ul class="upcoming-dates">
          <li>8:30am - Get Zara up and get her ready for relaxing morning bath (Tea Service)</li>
          <li>9:30am - Present time!!</li>
          <li>9:45am - Yoga and Hydrate</li>
          <li>10am - Cinnamon rolls ready for breakfast</li>
          <li>10:30am - Meditation, Bible, Journal</li>
          <li>11am - Scavenger Hunt</li>
          <li>11:30am - Light lunch (Rice + Sausage + Mushroom!)</li>
          <li>12:00pm - Get ready to head to beach</li>
          <li>12:30pm - 2pm - Beach walk and flying kites</li>
          <li>2:30pm - 4pm - Botanical gardens and special time on bench</li>
          <li>4pm - 5pm - Walking down 9th st (order boba!) and then head home</li>
          <li>5pm - 6:30pm - Arrive home and Daddy cooks while little one relaxes</li>
          <li>6:30pm - Candle lit anniversary spaghetti dinner w/ wine</li>
          <li>7pm - Special Anniversary slideshow/video</li>
          <li>7:30pm - Commitment Ceremony</li>
          <li>8pm - Presents again!</li>
          <li>8:30pm - Dirty slow dancing to Pink Floyd</li>
          <li>9pm - Spa experience</li>
          <li>10pm - Bedtime!</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Anniversary'
};
</script>

<style scoped>
.anniversary {
  padding: 0px 20px;
}

h1 {
  text-align: center;
  display: block;
}

.center {
  width: 100%;
  margin: 0 auto;
}

.section {
  display: flex;
  min-height: 400px;
  align-items: center;
  justify-content: center;
  margin: 30px 0;
}

.section ul li {
  padding: 0px 0 20px 25px;
  line-height: 18px;
  font-size: 120%;
  background: url('../assets/heart-icon.png') no-repeat;
}

.section ul li strong {
  padding-left: 6px;
  padding-right: 6px;
}

.upcoming-dates strong {
  color: #95deff;
}

.recent-updates strong {
  color: pink;
}

.upcoming-dates h3 {
  color: #df7971;
  text-transform: uppercase;
  font-size: 180%;
  margin-top: 0;
}

@media only screen and (max-device-width: 768px) {
  .section {
    flex-direction: column;
    min-height: auto;
    margin: 0 0 30px 0;
  }

  .section-bg {
    padding: 10px;
    width: 100%;
    box-sizing: border-box;
  }

  .section-bg h3,
  .section-reverse .section-bg h3 {
    transform: rotate(0deg);
    position: initial;
    margin: 0;
  }

  .section-content {
    width: 100%;
    box-sizing: border-box;
    padding-bottom: 0;
  }
}
</style>
