<template>
  <div>
    <div class="breadcrumbs">
      <div class="crumb-title">Shopping Lists</div>
    </div>
    <div class="empty-list" v-if="!lists.length">
      Loading...
    </div>

    <ul class="lists">
      <li v-for="(list, idx) in filterList(lists)" :key="idx">
        <button class="list-edit" @click="editList(list)">Edit</button>
        <router-link :to="{ name: 'list', params: { id: list.id } }">
          <div class="image-container">
            <img :src="list.image" />
          </div>
          <div class="list-title">{{ list.name }}</div>
        </router-link>
      </li>
      <li class="add-list" @click="showAdd = true" v-if="lists.length">
        <div>+ Create List</div>
      </li>
    </ul>

    <Modal v-if="showAdd" @close="showAdd = false">
      <h3 slot="header">Create a List</h3>
      <div slot="body" class="add-modal">
        <input type="text" placeholder="Name" v-model="addName" />
        <button @click="createList">Create</button>
      </div>
    </Modal>

    <Modal v-if="editingList" @close="editingList = null">
      <div slot="header" class="edit-modal-header">
        <h3>Editing List > {{ editingList.name }}</h3>
        <button @click="deleteList(editingList)">Delete</button>
      </div>
      <div slot="body">
        <div class="modal-field">
          <label>Name</label>
          <input type="text" placeholder="Name" v-model="editingList.name" />
        </div>
        <div class="modal-field">
          <label>Image</label>
          <ImagePicker
            class="image-picker"
            v-bind:images="editingList.images"
            v-model="editingList.image"
          />
        </div>
        <div class="modal-actions">
          <button @click="updateList">Edit List</button>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import { db } from '@/db';
import Modal from '@/components/Modal.vue';
import ImagePicker from '@/components/ImagePicker.vue';
import { slugify } from '@/utils';
import firebase from 'firebase';

export default {
  name: 'Lists',
  props: {
    group: String
  },
  components: {
    Modal,
    ImagePicker
  },
  data() {
    return {
      showAdd: false,
      editingList: null,
      addName: '',
      lists: []
    };
  },
  methods: {
    createList() {
      db.collection('lists')
        .doc(slugify(this.addName))
        .set({
          name: this.addName,
          date: firebase.firestore.Timestamp.fromDate(new Date())
        });
      this.showAdd = false;
    },
    editList(list) {
      db.collection('lists')
        .doc(list.id)
        .collection('items')
        .get()
        .then(query => {
          const images = query.docs.map(doc => doc.data().image);
          const newList = { ...list, id: list.id, url: null, images, file: null };
          if (images.length) {
            const currentImageIdx = newList.images.indexOf(newList.image);
            if (currentImageIdx > -1) {
              newList.currentImage = images[currentImageIdx];
              newList.carousel = currentImageIdx;
            } else {
              // eslint-disable-next-line prefer-destructuring
              newList.currentImage = images[0];
              newList.carousel = 0;
            }
          }
          this.editingList = newList;
        });
    },
    deleteList(list) {
      // eslint-disable-next-line no-restricted-globals
      if (confirm('Are you sure you want to delete this list and all items within?')) {
        db.collection('lists')
          .doc(list.id)
          .delete()
          .then(() => {
            this.editingList = null;
          });
      }
    },
    updateList() {
      const listId = this.editingList.id;
      db.collection('lists')
        .doc(listId)
        .update({
          name: this.editingList.name,
          image: this.editingList.image
        })
        .then(() => {
          this.editingList = null;
        });
    },
    filterList() {
      return this.lists.filter(item => item.id !== 'gifts');
    }
  },
  firestore() {
    return {
      lists: db.collection('lists')
    };
  }
};
</script>

<style lang="scss">
.breadcrumbs {
  padding: 20px 10px;
  background-color: pink;
  color: #fff;
  font-weight: bold;
  display: flex;
  align-items: center;
}
.crumb-title {
  text-align: center;
  width: 100%;
  font-size: 200%;
  font-family: 'Dancing Script', sans-serif;
}
.crumb-side {
  width: 30%;
}
.breadcrumbs a {
  color: #fff;
  text-decoration: none;
}
.lists {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  flex-wrap: wrap;
  overflow: hidden;
}
.add-list {
  text-transform: uppercase;
}
.image-picker {
  width: 100%;
}
.modal-field {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.modal-field label {
  padding-right: 20px;
}
.modal-field input {
  padding: 8px;
}
.edit-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.edit-modal-header button {
  height: 30px;
}
.add-modal input {
  padding: 6px;
  width: 100%;
  box-sizing: border-box;
}
.add-modal img {
  max-width: 200px;
}
.list-title {
  padding: 8px 0;
}

.lists li {
  position: relative;
  border: #eae8e4 0 solid;
  border-bottom-width: 1px;
  width: 33.33%;
  box-sizing: border-box;
  text-align: center;
  padding: 60px 30px;
  font-size: 16px;
  align-items: center;
  justify-content: center;
  display: flex;
  cursor: pointer;
  min-height: 300px;
}
.lists li:nth-child(n) {
  border-right-width: 1px;
}
.lists li:nth-child(2n) {
  border-right-width: 1px;
}
.lists li:nth-child(3n) {
  border-right-width: 0px;
}
.lists li img {
  max-width: 100%;
  max-height: 100%;
  vertical-align: middle;
}
.lists li a {
  text-decoration: none;
  color: #333;
  font-weight: 600;
  text-transform: uppercase;
}
.lists li:hover {
  background: #ffeaee;
  transition: 300ms all;
}

.lists .list-edit {
  display: none;
  cursor: pointer;
}

.lists li:hover .list-edit {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  border: none;
  padding: 12px 30px;
  z-index: 19;
  cursor: pointer;
}

.lists li:hover .list-edit:hover {
  background: red;
}

.empty-list {
  padding: 200px 0px;
  text-transform: uppercase;
  font-size: 30px;
  color: #999;
  text-align: center;
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .lists li {
    width: 50%;
  }

  .lists li:nth-child(2n) {
    border-right-width: 0px;
  }
}

@media only screen and (max-device-width: 767px) {
  .lists li,
  .products li {
    width: 100%;
    border-right-width: 0px !important;
  }
}
</style>
